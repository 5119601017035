import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const ContactUs = () => {


    const [inputData, setinputData] = useState({ name: '', email: '', subject: '', detail: '' });
    const [attachments, setAttachments] = useState();
    const location = useLocation();
    const isMobSupport = location?.pathname?.includes('/mobSupport');

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setinputData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };



    return (
        <>
            {/* <!-- hero area start --> */}
            <div className="hero-area page_banner" id="slider-area">
                <div className="container">
                    <h2> CONTACT US   </h2>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="/" className="text-warning" >Home</a></li>
                            <li className="breadcrumb-item active" aria-current="page"> CONTACT US   </li>
                        </ol>
                    </nav>
                </div>
            </div>
            {/* <section className="common_content_box"> */}

            <section id="contact" class="contact">
                <div class="container">
                    <div class="section-title aos-init aos-animate section-heading w-100 pb-55" data-aos="fade-up">
                        {/* <h2  className='text_gradient' > Contact Us  </h2>  */}
                    </div>
                    <div class="row align-items-start">
                        <div class="col-lg-12 aos-init aos-animate" data-aos="fade-right" data-aos-delay="100">
                            <div class="info row align-items-start">
                                <div class="email col-md-6 mt-md-0"><i class="bi bi-envelope"></i>
                                    <h4>Email :  <span>ambarsinghmaurya@gmail.com</span></h4>

                                    <div class="phone col-md-6 mt-md-0"><i class="bi bi-phone"></i>
                                        <h4>Call : <span>+91-9005979326</span></h4>

                                    </div>
                                    <br />
                                    <div class="address col-md-12"><i class="bi bi-geo-alt"></i>
                                        <h4>Location: </h4>
                                        <p> PARAXION MARKET CONSULTANTS PRIVATE LIMITED <b /><br /><b /><br />
                                            Registered Address: C/O SHILA DEVI, NONAR TULSI ASHRAM, SAKALDIHA, CHANDAULI, CHANDAULI, UTTAR PRADESH, 232108, NONAR, Uttar Pradesh, PIN: 232108 <br /><br />
                                            Operational Address: C/O SHILA DEVI, NONAR TULSI ASHRAM, SAKALDIHA, CHANDAULI, CHANDAULI, UTTAR PRADESH, 232108, NONAR, Uttar Pradesh, PIN: 232108
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />
                    {/* <form className="" >
                            <div className="row" >

                                <div className="col-lg-6" >
                                    <div className="form-group field-box mb-3 mb-md-4" >
                                        <label className="form-label">Enter Name</label>
                                        <input type="text" name="name" onChange={(e) => handleInputChange(e)} />
                                    </div>
                                </div>

                                <div className="col-lg-6" >
                                    <div className="form-group field-box mb-3 mb-md-4" >
                                        <label className="form-label">Email Address</label>
                                        <input type="text" name="email" onChange={(e) => handleInputChange(e)} />
                                    </div>
                                </div>
                                <div className="col-lg-12" >
                                    <div className="form-group field-box mb-3 mb-md-4" >
                                        <label className="form-label">Enter Subject </label>
                                        <input type="text" name="subject" onChange={(e) => handleInputChange(e)} />
                                    </div>
                                </div>
                                <div className="col-lg-12" >
                                    <div className="form-group field-box mb-3 mb-md-4" >
                                        <label className="form-label">Enter Details </label>
                                        <textarea rows="5" name="detail" onChange={(e) => handleInputChange(e)}></textarea>
                                    </div>
                                </div> 
                                <div className="col-lg-12" >
                                    <button className="hero-btn w-100 " type="button">  Submit  </button>
                                </div>

                            </div>
                        </form> */}

                </div>
            </section>
            {/* </section> */}
            {/* <!-- Copyright Area Start --> */}
            <div className="copyright-area  ptb-70 pt-0 pb-4">
                <div className="container">
                    {/* <!-- Contact address left --> */}
                    <div className="conct-border row align-items-center">
                        <div className="col-md-5">
                            <a href="/"> <img src="img/icon/logo.svg" /> </a>
                        </div>
                        <div className="col-md-7">
                            <div className="single-address">
                                <a href="/fairplay"  >KheloIndiaKhelo Fairplay</a>
                                <a href="/termsconditions">Terms & Conditions</a>
                                <a href="/privacypolicy">Privacy Policy</a>
                                <a href="/legality"> Legality </a>
                                <a href="/responsible-gaming-policy"> Responsible Gaming  </a>
                                {/* <a href="/anti-money-laundering-policy">Anti-Money Laundering  </a> */}
                                <a href="/refund-policy"> Refund Policy  </a>
                                <a href="/contact"> Contact us    </a>
                            </div>
                        </div>
                    </div>
                    {/* <!-- Contact address left --> */}
                    {/* <!-- Copyright right --> */}
                    <div className="row">
                        <div className="col-12">
                            <div className="copyright-area text-center">
                                {/* <!-- Copyright social --> */}
                                {/* <!-- <div className="contact-social text-center ptb-50 pb-35">
                                    <ul>
                                        <li>
                                            <a href="#"><i className="icofont icofont-social-facebook"></i></a>
                                        </li>
                                        <li>
                                            <a href="#"><i className="icofont icofont-social-twitter"></i></a>
                                        </li>
                                        <li>
                                            <a href="#"><i className="icofont icofont-social-pinterest"></i></a>
                                        </li>
                                        <li>
                                            <a href="#"><i className="icofont icofont-social-vimeo"></i></a>
                                        </li>
                                        <li>
                                            <a href="#"><i className="icofont icofont-social-google-plus"></i></a>
                                        </li>
                                    </ul>
                                </div> --> */}
                                {/* <!-- Copyright social --> */}
                                <div className="copyright-text">
                                    <p>Copyright &copy;  <a href="#"> Khelo India Khelo </a> All Rights Reserved.  Design by <a href="https://appinop.com" target="_blank"  >Appinop Technologies</a><br />KheloIndiaKhelo is a part of PARAXION MARKET CONSULTANTS PRIVATE LIMITED</p>

                                    <p>
                                        <b>DISCLAIMER</b><br />
                                        This game may be habit-forming or financially risky. Play responsibly. KheloIndiaKhelo is the biggest social gaming app in India by number of games, languages and exciting formats on the platform. KheloIndiaKhelo is only available for people who are above 18 years of age. KheloIndiaKhelo is available only in those Indian states where skill gaming is allowed by regulations.Khelo India Khelo Pvt. Ltd. is the sole owner of and reserves the right to “KheloIndiaKhelo” trademark, logos, assets, content, information, etc. used in the website except the third party content. Khelo India Khelo Pvt. Ltd. does not acknowledge the accuracy or reliability of third party content.
                                    </p>
                                </div>
                                {/* <!-- Copyright text --> */}
                            </div>
                        </div>
                    </div>
                    {/* <!-- Copyright right --> */}
                </div>
            </div >
            {/* <!-- Copyright Area End --> */}
        </>
    )
}

export default ContactUs
